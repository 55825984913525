import React from "react";
import dashboards from "./assets/dashboards.svg";

function Home() {
  return (
    <>
      <div className="flex justify-center">
        <section id="hero" className="text-gray-600 body-font bg-white bg-gradient-to-r from-[#348fec] to-[#0567d8] lg:rounded-2xl lg:m-2  lg:max-w-[1200px]">
          <div className="container mx-auto flex md:px-24 md:py-10 md:flex-row flex-col items-center">
            <div
              className="lg:flex-grow mt-5 md:mt-0 md:w-1.5/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
              <h1
                className="text-3xl my-4 font-extrabold tracking-tight mb-3 text-gray-900 dark:text-gray-100 sm:leading-10 md:text-5xl md:leading-20">
                Soluções Digitais sob medida <br /> para o seu negócio
              </h1>
              <p className="text-white text-[16px] md:text-[20px] max-w-[60%] mb-12">
                Confiança, agilidade e inovação. Nossa missão é <br /> acelerar o
                crescimento da sua empresa com tecnologia de ponta.
              </p>
            </div>
            <div className="lg:max-w-lg lg:w-full mb-5 md:mb-0 md:w-1/2 w-3/6">
              <img className="object-cover object-center rounded scale-[2] md:scale-[1.5]" alt="Vários dashboards" src={dashboards} />
            </div>
          </div>
        </section>
      </div>
      <div className="flex justify-center">
        <section id="support" className="text-gray-600 body-font bg-white m-2 lg:max-w-[1200px]">
          <div className="container mx-auto flex md:px-24 md:py-10 md:flex-row flex-col items-center">
            <div>

            <h2
              className="text-2xl my-4 font-extrabold tracking-tight mb-3 text-gray-100 dark:text-gray-900 sm:leading-10 md:text-3xl md:leading-20">
              Suporte, segurança e
              usabilidade
            </h2>
            <p className="text-gray-500 text-[14px] md:text-[16px] max-w-[60%] mb-12">
              Construir uma solução de nível empresarial não precisa ser um pesadelo nem custar muito. Nossos times e soluções buscam ser totalmente flexíveis e se adaptar a sua necessidade.
              </p>
                </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;

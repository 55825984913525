import React from "react";
import "./Header.scss";
import Logo from "../logo/Logo";
import { Button, Layout } from "antd";
import { NavLink } from "react-router-dom";

function Header() {
  function setActiveStyle(isActive: boolean) {
    return {
      borderBottom: isActive ? "solid 3px #0567D8" : "none",
    };
  }

  return (
    <Layout.Header className="header">
      <Logo />
      <div className="menu">
        <NavLink to="/" style={({ isActive }) => setActiveStyle(isActive)}>
          Home
        </NavLink>
        <NavLink to="/about" style={({ isActive }) => setActiveStyle(isActive)}>
          Quem somos
        </NavLink>
        <NavLink
          to="/contato"
          style={({ isActive }) => setActiveStyle(isActive)}
        >
          Contato
        </NavLink>
        <NavLink
          to="/carreiras"
          style={({ isActive }) => setActiveStyle(isActive)}
        >
          Carreiras
        </NavLink>
      </div>
      {/* <Button type="default" className="button">
        Agende uma conversa
      </Button> */}
    </Layout.Header>
  );
}

export default Header;

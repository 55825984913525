import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/home/Home";
import Contato from "./pages/contato/Contato";
import About from "./pages/about/About";
import Carreiras from "./pages/carreiras/Carreiras";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { ConfigProvider } from "antd";

function App() {
  return (
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Mulish",
          },
        }}
      >
          <BrowserRouter>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contato" element={<Contato />} />
              <Route path="/about" element={<About />} />
              <Route path="/carreiras" element={<Carreiras />} />
            </Routes>
            <Footer />
          </BrowserRouter>
      </ConfigProvider>
  );
}

export default App;
